import queryString from 'query-string';


export const putFiltersInUrl = (object,url) => {
    //extract query items from url 
    const queryItems = queryString.parseUrl(url,{arrayFormat: 'index'});
    
    //build the new url only with query  
    //console.log(queryString.stringify(queryItems.query,{arrayFormat: 'index'}));
    const newUrl = queryString.stringify(queryItems.query);
    object.props.history.push({search: newUrl});
}

export const substractItemFromUrl = (url) => {
    const queryObj = queryString.parseUrl(url);
    return queryObj.query;

}

export const getItemFromUrl = (url, key) => {
    const queryObj = queryString.parseUrl(url);
    return queryObj.query[key];
}

// const addQuery = (key, value) => {
//     let pathname = props.location.pathname; 
//    // returns path: '/app/books'
//     let searchParams = new URLSearchParams(props.location.search); 
//    // returns the existing query string: '?type=fiction&author=fahid'
//     searchParams.set(key, value);
//     this.props.history.push({
//              pathname: pathname,
//              search: searchParams.toString()
//        });
//    };


// const removeQuery = (key) => {
//     let pathname = props.location.pathname; 
//    // returns path: '/app/books'
//     let searchParams = new URLSearchParams(props.location.search); 
//    // returns the existing query string: '?type=fiction&author=fahid'
//     searchParams.delete(key);
//     this.props.history.push({
//              pathname: pathname,
//              search: searchParams.toString()
//        });
//    };