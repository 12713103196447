import {AUTH_SUCCESS_REDIRECT_URL} from "../constants/auth";

export const getRedirectLocation = (authSession) => {
    let location = AUTH_SUCCESS_REDIRECT_URL;
    if (localStorage.getItem("previousLocation")) {
        location = JSON.parse(localStorage.getItem("previousLocation")).value;
        location = location.slice(1, location.length - 1);
    }

    if (authSession && localStorage.getItem("previousLocation")) {
        localStorage.removeItem("previousLocation");
    }
    return location;
}