import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import {Redirect, withRouter} from 'react-router-dom';

import ResizeDetector from 'react-resize-detector';

import AppHeader from "./AppHeader";
import AppSidebar from "./AppSidebar";
import Routes from './Routes';
import {loadFromLocalStorage, saveToLocalStorage} from "../utils/storage";
import {getItemFromUrl} from "../utils/interface/queryHandler";
import {NOTIFICATION_TOKEN} from "../utils/constants/constants";
import {AUTH_PATHS} from "../utils/constants/auth";
import {getRedirectLocation} from "../utils/service/authRedirect";

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closedSmallerSidebar: false,
            currentPath: window.location.pathname
        };
    }

    componentDidMount() {
        if (!this.props.authSession && AUTH_PATHS.indexOf(this.props.location.pathname) === -1) {
            saveToLocalStorage("previousLocation", this.props.location.pathname + this.props.location.search);
        }

        let notificationToken = getItemFromUrl(this.props.location.search, NOTIFICATION_TOKEN);
        if (notificationToken) {
            if (!loadFromLocalStorage(NOTIFICATION_TOKEN)) {
                saveToLocalStorage('notificationToken', notificationToken)
            }
        }

        if (this.props.authSession && AUTH_PATHS.indexOf(this.props.location.pathname) > -1) {
            window.location.pathname = getRedirectLocation(this.props.authSession);
        }
    }

    componentDidUpdate() {
        if (window.location.pathname !== this.state.currentPath) {
            this.setState({currentPath: window.location.pathname});
        }
    }

    checkProfile = () => {
        const path = window.location.pathname;
        if (path === '/logout') {
            return null;
        }

        if (this.props.authSession) {
            if (this.props.authSession.agentProfile) {

                if (! this.props.authSession.agentProfile.address) {
                    const profilePath = "/profile/location";
                    if (path !== profilePath) {
                        return <Redirect to={profilePath}/>
                    }

                } else if (! this.props.authSession.agentProfile.schedules[0]) {
                    const schedulePath = "/profile/schedule";

                    if (path !== schedulePath) {
                        return <Redirect to={schedulePath} />
                    }

                } else if (this.props.authSession.agentProfile.company) {
                    if (!loadFromLocalStorage("savedBillingData")) {
                        const invoicePath = "/profile/invoice";

                        if (path !== invoicePath) {
                            return <Redirect to={invoicePath} />
                        }
                    }

                }
            }
        }


        return null;
    }

    render() {
        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
        } = this.props;

        return this.checkProfile() ?? (
            <ResizeDetector
                handleWidth
                render={({width}) => (
                    <Fragment>
                        <div className={cx(
                            "app-container app-theme-" + colorScheme,
                            { "fixed-header": enableFixedHeader },
                            { "fixed-sidebar": enableFixedSidebar || width < 1250 },
                            { "fixed-footer": enableFixedFooter },
                            { "closed-sidebar": enableClosedSidebar || width < 1250 },
                            {
                                "closed-sidebar-mobile": closedSmallerSidebar || width < 1250,
                            },
                            { "sidebar-mobile-open": enableMobileMenu },
                            { "body-tabs-shadow-btn": enablePageTabsAlt }
                        )}>
                            {
                                !this.props.authSession && (this.state.currentPath !== '/' && this.state.currentPath !== '/register' && this.state.currentPath !== '/password-request') ? <Redirect to={"/"}/>
                                    : this.props.authSession
                                    ?
                                    <Fragment>
                                        <AppHeader/>
                                        <div className="app-main">
                                            <AppSidebar/>
                                            <div className="app-main__outer">
                                                <div className="app-main__inner">
                                                    <Routes/>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                    : <Routes/>
                            }
                        </div>
                    </Fragment>
                )}
            />
        )
    }
}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
    authSession: state.authSession

});

export default withRouter(connect(mapStateToProp)(Main));