import {Route, Switch} from 'react-router-dom';
import React, {Fragment, lazy, Suspense} from 'react';
import LoaderComponent from "../components/Common/Loader";

import {ToastContainer,} from 'react-toastify';

const Dashboard = lazy(() => import('../containers/Dashboard'));
const Bookings = lazy(() => import('../containers/Bookings'));
const BookingRequests = lazy(() => import('../containers/BookingRequests'));
const Schedule = lazy(() => import('../containers/profile/Schedule'));
const Revenues = lazy(() => import('../containers/Revenues'));
const Targets = lazy(() => import('../containers/Targets'));
const Login = lazy(() => import('../containers/Login'));
const Register = lazy(() => import('../containers/Register'));
const PasswordRequest = lazy(() => import('../containers/PasswordRequest'));
const Ticket = lazy(() => import('../containers/Ticket'));

const Logout = lazy( () => import('../containers/Logout'))
const NotFound = lazy(() => import('../components/NotFound'));

const PersonalData = lazy(() => import('../containers/profile/PersonalData'));
const Location = lazy(() => import('../containers/profile/Location'));
const Services = lazy(() => import('../containers/profile/Services'));
const BillingData = lazy(() => import('../containers/profile/BillingData'));


const Routes = () => {

    return (
        <Fragment>
            <Suspense fallback={<LoaderComponent />}>
                <Switch>
                    <Route exact path="/" component={Login}/>
                    <Route exact path="/dashboard" render={routeProps => <Dashboard {...routeProps}/>}/>
                    <Route exact path="/bookings" render={routeProps => <Bookings {...routeProps}/>}/>
                    <Route exact path="/requests" render={routeProps => <BookingRequests {...routeProps}/>}/>
                    <Route exact path="/targets" component={Targets}/>
                    <Route exact path="/revenues" component={Revenues}/>

                    <Route exact path="/profile" component={PersonalData}/>
                    <Route exact path="/profile/personal-data" component={PersonalData}/>
                    <Route exact path="/profile/location" component={Location}/>
                    <Route exact path="/profile/services" component={Services}/>
                    <Route exact path="/profile/invoice" component={BillingData}/>
                    <Route exact path="/profile/schedule" component={Schedule}/>


                    <Route exact path="/register" component={Register}/>
                    <Route exact path="/logout" component={Logout}/>
                    <Route exact path="/password-request" component={PasswordRequest}/>
                    <Route exact path="/help" component={Ticket}/>
                    <Route exact path='*' component={NotFound} />
                </Switch>
            </Suspense>
            <ToastContainer/>
        </Fragment>
    )
};

export default Routes;