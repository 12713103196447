export default function(state = null, action) {
    switch (action.type) {
        case "UserAuthenticated":
            return action.payload;
        case "UserLoggedOut":
            return action.payload;
        case "UserLoadedToken":
            return action.payload;
        case "AccountCreated":
            return action.payload;
        case "SessionUpdated":

            return {
                ...action.payload,
                token: state.token,
            }
        case "ProfileImagePost":
            return {
                ...state,
                image: action.payload.image,
            };
        case "ProfileAddressUpdated":
            return {
                ...state,
                agentProfile: {
                    ...state.agentProfile,
                    address: action.payload
                }
            }
        case "ScheduleCreated":
            return {
                ...state,
                agentProfile: {
                    ...state.agentProfile,
                    schedules: [action.payload]
                }
            }
        case "UserAuthenticationFailed":
        case "AccountCreationFailed":
        case "SessionUpdatingFailed":
        case "UserUnauthorized":
        default:
            return state;
    }
}