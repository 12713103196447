import React, {Fragment} from 'react';

import {Link, Redirect} from "react-router-dom";

import {Button, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, UncontrolledButtonDropdown} from 'reactstrap';

import {faAngleDown,} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {logoutAction} from "../../redux/actions/Authentication/logoutAction";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PerfectScrollbar from 'react-perfect-scrollbar';

class UserBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
        };
    }

    render() {
        return (
            this.props.authSession ? <Fragment>
                        <div className="widget-content-wrapper">
                            <div className="widget-content-left header-dropdown-menu">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color="link" className="p-0">
                                        <img width={42} className="rounded-circle" src={this.props.authSession.image} alt=""/>
                                        <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown}/>
                                    </DropdownToggle>
                                    <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                        <div className="dropdown-menu-header">
                                            <div className="dropdown-menu-header-inner bg-zumzi">
                                                <div className="menu-header-image opacity-2"
                                                />
                                                <div className="menu-header-content text-left">
                                                    <div className="widget-content p-0">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left mr-3">
                                                                <img width={42} className="rounded-circle" src={this.props.authSession.image}
                                                                     alt=""/>
                                                            </div>
                                                            <div className="widget-content-left">
                                                                <div className="widget-heading">
                                                                    {this.props.authSession.name}
                                                                </div>
                                                            </div>
                                                            <div className="widget-content-right mr-2">
                                                                <Link to="/">
                                                                    <Button className="btn-pill btn-shadow btn-shine"
                                                                            color="focus" onClick={this.props.logoutAction}>
                                                                        Logout
                                                                    </Button>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            <PerfectScrollbar>
                                                <Nav vertical>
                                                    <NavItem className="mt-1">
                                                        <NavLink href="/profile/personal-data" className="brand-secondary-color">
                                                            <i className="pe-7s-user mr-1" style={{fontSize: '1.4rem'}}/> Date personale
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="mt-1">
                                                        <NavLink href="/profile/location" className="brand-secondary-color">
                                                            <i className="pe-7s-map mr-1" style={{fontSize: '1.4rem'}}/> Locatie
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="mt-1">
                                                        <NavLink href="/profile/services" className="brand-secondary-color">
                                                            <i className="pe-7s-portfolio mr-1" style={{fontSize: '1.4rem'}}/> Servicii
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem className="mt-1">
                                                        <NavLink href="/profile/schedule" className="brand-secondary-color">
                                                            <i className="pe-7s-clock mr-1" style={{fontSize: '1.4rem'}}/> Programul Meu
                                                        </NavLink>
                                                    </NavItem>
                                                    {
                                                        this.props.authSession?.agentProfile?.company
                                                        ? <NavItem className="mt-1">
                                                                <NavLink href="/profile/invoice" className="brand-secondary-color">
                                                                    <i className="pe-7s-note2" style={{fontSize: '1.4rem', marginLeft: '2px', marginRight: '8px'}}/> Date companie
                                                                </NavLink>
                                                            </NavItem>
                                                        : null
                                                    }


                                                    <NavItem className="mt-2" >
                                                        <NavLink href="/help" className="brand-secondary-color" style={{borderTop: '1px solid grey'}}>
                                                            <i className="pe-7s-help1 mr-1" style={{fontSize: '1.4rem'}}/> Ajutor
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </PerfectScrollbar>
                                        </div>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>

                            </div>
                            <div className="widget-content-left  ml-3 header-user-info">
                                <div className="widget-heading">
                                    {this.props.authSession.name}
                                </div>
                            </div>
                        </div>

            </Fragment> : <Redirect to="/" />
        )
    }
}

const mapStateToProps = state => ({
    authSession: state.authSession,
});

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        logoutAction: logoutAction
    }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(UserBox);