import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import {BrowserRouter} from 'react-router-dom';
import './assets/base.scss';
import Main from './layout/';
import {Provider} from 'react-redux';
import {loadFromLocalStorage} from "./utils/storage";
import {reauthenticateAction} from "./redux/actions/Authentication/reauthenticateAction";
import {applyMiddleware, combineReducers, createStore} from "redux";
import reducers from "./redux/reducers";
import {composeWithDevTools} from "redux-devtools-extension";
import reduxPromise from "redux-promise"

const store = createStore(
    combineReducers({
        ...reducers
    }),
    composeWithDevTools(applyMiddleware(reduxPromise))
);

const authSession = loadFromLocalStorage('authSession');
if (authSession) {
    store.dispatch(reauthenticateAction(authSession))
}


const rootElement = document.getElementById('root');
const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <Component/>
            </BrowserRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept('./layout', () => {
        const NextApp = require('./layout').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();