export const MainNav = [
  {
    icon: 'pe-7s-portfolio',
    label: 'Panou Principal',
    to: '/dashboard',
  },
];

export const ComponentsNav = [
  {
    icon: 'pe-7s-paper-plane',
    label: 'Cereri',
    to: '/requests',
  },
  {
    icon: 'pe-7s-date',
    label: 'Sedinte',
    to: '/bookings',
  },
  {
    icon: 'pe-7s-target',
    label: 'Targeturi',
    to: '/targets',
  },
  {
    icon: 'pe-7s-cash',
    label: 'Incasari',
    to: '/revenues',
  },
];
